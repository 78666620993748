import imageLoad from "src/assets/images/icon/load.svg";

export function LoadingComponent() {
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <img src={imageLoad} className="w-36" />
      <h5 className="text-center font-bold f-h5 text-gray-900">Loading...</h5>
    </div>
  );
}
