import { useContext, useEffect, useRef, useState } from "react";
import { json, useNavigate, useParams } from "react-router-dom";
import bubbleTwice from "src/assets/images/bubble-twice.svg";
import iconSuccess from "src/assets/images/icon/success.svg";
import { ButtonComponent } from "src/components/button/button-component";
import { CartContext } from "src/context/CartContext";
import { JSONContext } from "src/context/JSONContextProvider";
import html2canvas from "html2canvas";
import { rupiahFormat } from "src/helper/rupiahFormat";

export function BillPage() {
  const navigate = useNavigate();

  const { idTable } = useParams();
  const { cart } = useContext(CartContext);
  const { JSON, setJSON } = useContext(JSONContext);

  useEffect(() => {
    if (cart.length == 0) {
      window.location.href = `/${idTable}`;
    }
  }, []);

  const handleDownloadImage = async () => {
    const element = document.getElementById("print"),
      canvas = await html2canvas(element),
      data = canvas.toDataURL("image/jpg"),
      link = document.createElement("a");

    link.href = data;
    link.download = "BILL_ROOSTER_" + JSON.idOrderCustomer + ".jpg";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="bg-primary-border relative flex flex-col justify-center items-center py-6 min-h-screen">
      <img src={bubbleTwice} className="w-full h-full absolute" />
      <div
        className="w-11/12 relative bg-white flex flex-col items-center justify-between rounded-2xl p-5"
        id="print"
      >
        <div className="text-center flex flex-col items-center mb-4">
          <img src={iconSuccess} className="w-14" />
          <h3 className="f-h3 mt-4">Sukses</h3>
          <p className="f-p2-r mt-2">Pembayaran berhasil</p>
        </div>

        <BillSquare data={JSON} cart={cart} />
      </div>

      <div className="w-11/12 relative mt-6">
        <div className="w-full">
          <ButtonComponent
            type="light"
            title="Download Struk"
            handle={() => {
              handleDownloadImage();
            }}
          />
        </div>
      </div>
    </div>
  );
}

function BillSquare({ data, cart }) {
  return (
    <div className="w-full">
      <div className="text-center">
        <h1 className="f-h4 mt-0">The Rooster Coffee Company</h1>
        <p className="f-p2-r">
          Jl. Yos Sudarso No.21, Ketel, Sidokumpul
          <br />
          Kec. Sidoarjo, Kab. Sidoarjo, Jawa Timur 61219
          <br />
          IG. @roostercoffee.id, M. 0857 8888 0857
        </p>
      </div>
      <div className="flex items-center mt-4">
        <div className="grow">
          <h1 className="f-h1 font-bold">{data.tableNumber}</h1>
        </div>
        <div className="text-right">
          <p className="f-p2-m">Payment by</p>
          <p className="f-p2-r">QRIS</p>
        </div>
      </div>
      <div className="mt-2">
        <p className="f-p1-m">Invoice: {data.idOrderCustomer}</p>
      </div>
      <div className="mt-4 grid grid-cols-2 gap-1">
        <div className="col-span-1">
          <div className="">
            <p className="f-p2-r">Service</p>
            <p className="f-p2-m ">Dine In</p>
          </div>
          <div className="mt-2">
            <p className="f-p2-r">Customer</p>
            <p className="f-p2-m line-clamp-1">
              {data?.user?.phone ?? "Guest"}
            </p>
          </div>
        </div>
        <div className="col-span-1">
          <div className="">
            <p className="f-p2-r">Order Time</p>
            <p className="f-p2-m ">{data.orderTime}</p>
          </div>
          <div className="mt-2 ">
            <p className="f-p2-r">Collected By</p>
            <p className="f-p2-m ">{data.machine}</p>
          </div>
        </div>
      </div>
      <div className="border border-dashed w-full my-4 border-neutral-600"></div>
      <div className="mt-2">
        <div className="bg-neutral-100">
          <h5 className="f-h5">Order Details</h5>
          {cart &&
            cart.map((item, index) => {
              if (item.buy == true)
                return (
                  <div className="list-group mt-2" key={`item_${index}`}>
                    <div className="grid grid-cols-12">
                      <div className="col-span-8">
                        <h5 className="f-h5">{item.name}</h5>
                      </div>
                      <div className="col-span-4">
                        <p className="f-p2-r text-right">x{item.amount}</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="f-p2-r text-left">{item.notes}</p>
                      </div>
                    <div className="grid grid-cols-12">
                      <div className="col-span-8">
                      <p className="f-p2-r text-left">
                          {rupiahFormat(item.originalPrice * item.amount)}
                        </p>
                      </div>
                      <div className="col-span-4">
                        <p className="f-p2-r text-right">
                          {rupiahFormat(item.originalPrice)}
                        </p>
                      </div>
                    </div>
                  </div>
                );
            })}
        </div>
      </div>
      <div className="border border-dashed w-full my-4 border-neutral-600"></div>
      <div className="mt-2">
        <div className="flex justify-between items-center">
          <p className="f-p2-r">Sub Total</p>
          <p className="f-p2-r">{rupiahFormat(data.subTotal)}</p>
        </div>
        <div className="flex justify-between items-center">
          <p className="f-p2-r">Discount</p>
          <p className="f-p2-r">{rupiahFormat(data.discount)}</p>
        </div>
        <div className="flex justify-between items-center">
          <p className="f-p2-r">Tax 10%</p>
          <p className="f-p2-r">{rupiahFormat(data.tax)}</p>
        </div>
      </div>
      <div className="border border-dashed w-full my-4 border-neutral-600"></div>
      <div className="mt-2 flex items-center justify-between">
        <h5 className="f-h5">Total</h5>
        <h5 className="f-h5">{rupiahFormat(data.total)}</h5>
      </div>
    </div>
  );
}
