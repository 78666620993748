import { useNavigate, useParams } from "react-router-dom";
import imageEmpty from "src/assets/images/icon/cart.svg";
import { ButtonComponent } from "src/components/button/button-component";

export function EmptyComponent() {
  const navigate = useNavigate();
  const { idUser } = useParams();
  return (
    <div
      style={{ height: "calc(100vh - 80px)" }}
      className="w-full px-4 flex flex-col justify-center items-center "
    >
      <img src={imageEmpty} className="" />
      <h1 className="mt-5 text-center font-bold h-3 text-gray-900">
        Your cart is empty
      </h1>
      <div className="w-full mt-10">
        <ButtonComponent
          title="Menu"
          type="button"
          handle={() => {
            navigate(`/${idUser}/menu`);
          }}
        />
      </div>
    </div>
  );
}
