import { gql } from "@apollo/client";

export const GET_PRODUCTS = gql`
  query Products($submenuId: ID!) {
    products(submenuId: $submenuId) {
      id
      name
      originalPrice
      price
      stock
      description
      displayPic
    }
  }
`;

export const GET_PRODUCT = gql`
  query Product($productId: ID!) {
    product(id: $productId) {
      description
      displayPic
      id
      menuId
      name
      originalPrice
      price
      stock
    }
  }
`;
