import bubbleTwice from "src/assets/images/bubble-twice.svg";
import { NavbarComponent } from "src/components/navbar/navbar-component";
import { FiArrowLeft, FiHome } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import imageExampleQRcode from "src/assets/images/example-qrcode.png";
import imageQris from "src/assets/images/qris.svg";
import { ButtonComponent } from "src/components/button/button-component";
import { useContext, useEffect, useState } from "react";
import { CartContext } from "src/context/CartContext";
import { useMutation } from "@apollo/client";
import { CREATE_ORDER } from "src/graphql/mutation/CreateOrder";
import { CREATE_QRIS_TRANSACTION } from "src/graphql/mutation/CreateQrisTransaction";
import { TransactionServices } from "src/services/TransactionServices";
import { UserContext } from "src/context/UserContext";
import { PrinterServices } from "src/services/PrinterServices";
import { JSONContext } from "src/context/JSONContextProvider";
import { rupiahFormat } from "src/helper/rupiahFormat";

export function PaymentPage() {
  const navigate = useNavigate();
  const { idMember, idTable } = useParams();
  const transactionServices = new TransactionServices();
  const printerServices = new PrinterServices();

  const { cart } = useContext(CartContext);

  const { user } = useContext(UserContext);
  const { JSON, setJSON } = useContext(JSONContext);

  const [dataOrder, setDataOrder] = useState(null);
  const [qrCode, setQrCode] = useState(null);

  const [
    createOrder,
    {
      loading: loadingCreateOrder,
      error: errorCreateOrder,
      data: dataCreateOrder,
    },
  ] = useMutation(CREATE_ORDER);

  const [
    createQrisTransaction,
    {
      loading: loadingCreateQrisTransaction,
      error: errorCreateQrisTransaction,
      data: dataCreateQrisTransaction,
    },
  ] = useMutation(CREATE_QRIS_TRANSACTION);

  useEffect(() => {
    if (cart.length > 0) {
      handlePayment();
    } else {
      navigate(`/${idTable}/cart`);
    }
  }, []);

  async function handlePayment() {
    const tempOrder = cart.map((item) => {
      if (item.buy == true)
        return { productId: item.id, quantity: item.amount, notes: item.notes };
    });

    const resCreateOrder = await createOrder({
      variables: {
        input: {
          orderInput: tempOrder,
          orderType: "DINE_IN",
          tableNumber: idTable,
        },
      },
    });

    if (loadingCreateOrder == false && errorCreateOrder == undefined) {
      setDataOrder(resCreateOrder.data.createOrderCustomer.orderCustomer);

      const resPaymentQris = await handlePaymentQris(
        resCreateOrder.data.createOrderCustomer.orderCustomer
      );
      return resPaymentQris;
    } else {
      return false;
    }
  }

  async function handlePaymentQris(resOrder) {
    try {
      const res = await createQrisTransaction({
        variables: {
          input: {
            orderCustomerId: resOrder.id,
          },
        },
      });

      if (
        loadingCreateQrisTransaction == false &&
        errorCreateQrisTransaction == undefined
      ) {
        setQrCode(res.data.createQrisTransaction.transaction.url);

        handlePrint({ orderId: res.data.createQrisTransaction.transaction.id });

        const resMidtrans = await handleWaiting({
          id: res.data.createQrisTransaction.transaction.id,
          url: res.data.createQrisTransaction.transaction.url,
          resOrder: resOrder,
          loop: true,
        });
        return resMidtrans;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      handlePayment();
    }
  }

  async function handleWaiting({ id, url, resOrder }) {
    const res = await transactionServices.isTransactionSettled({ id });

    if (res?.data?.isTransactionSettled == true) {
      // handlePrint({ orderId: resOrder.id });
      navigate(`/${idTable}/bill`);
    } else {
      setTimeout(() => {
        handleWaiting({ id: id, url: url, resOrder: resOrder });
      }, 2000);
    }
  }

  const handlePrint = async ({ orderId }) => {
    const subTotal = cart.reduce(
      (temp, x) => (x.buy == true ? temp + x.originalPrice * x.amount : temp),
      0,
      0
    );

    const time = new Date().toLocaleString("id-ID", {
      timeZone: "Asia/Jakarta",
    });

    const baseUrl = window.location.href.split("/");
    const repeatOrderUrl = baseUrl[0] + "//" + baseUrl[2] + `/${idTable}`;

    const tax = subTotal * 0.1;
    const json = {
      printing: ["DISMORE_KITCHEN", "DISMORE_KITCHEN"],
      tableNumber: idTable,
      idOrderCustomer: orderId,
      typeService: "DINE IN",
      user: { phone: idMember },
      orderTime: time,
      machine: "Self Service | Rooster",
      orders: cart.map((x) => {
        return {
          name: x.name,
          amount: x.amount,
          notes: x.notes,
          originalPrice: rupiahFormat(x.originalPrice),
          totalPrice: rupiahFormat(x.originalPrice * x.amount),
        };
      }),
      subTotal: rupiahFormat(subTotal),
      discount: rupiahFormat(0),
      tax: rupiahFormat(tax),
      total: rupiahFormat(subTotal + tax),
      repeatOrderUrl: repeatOrderUrl,
    };

    setJSON(json);
    const res = await printerServices.submitPrintJob({ json, id: orderId });

    // navigate(`/${idTable}/bill`);
  };

  return (
    <div className="bg-primary-border relative flex flex-col justify-center items-center h-screen overflow-hidden">
      <img src={bubbleTwice} className="w-full h-full absolute" />
      <div className="w-full absolute top-0">
        <NavbarComponent
          title="Pembayaran"
          type="light"
          leftIcon={null}
          rightIcon={null}
        />
      </div>
      <div className="absolute w-11/12 bg-white flex flex-col items-center justify-between rounded-2xl p-5">
        <div className="flex justify-between w-full">
          <div className="flex flex-col">
            <h5 className="f-h5">Total Pembayaran</h5>
            <h4 className="f-h4 text-secondary-border mt-1">
              {rupiahFormat(dataOrder?.totalAmount) || "-"}
            </h4>
          </div>
          <div>
            <img src={imageQris} className="h-10" />
          </div>
        </div>

        <div className="mt-0">
          <img src={qrCode} className="h-60" />
          {/* <img src={imageExampleQRcode} className="h-60" /> */}
        </div>

        <div className="mt-0">
          <p className="f-p2-sb text-center">
            Scan QR Code diatas untuk melakukan pembayaran
          </p>
        </div>

        <div className="mt-6 w-full">
          <ButtonComponent title="Saya sudah bayar" handle={() => {}} />
        </div>
      </div>
    </div>
  );
}
