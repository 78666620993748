import { useContext, useRef, useState } from "react";
import { FiArrowLeft, FiHome } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { NavbarComponent } from "src/components/navbar/navbar-component";
import { CartContext } from "src/context/CartContext";
import { EmptyComponent } from "./component/EmptyComponent";
import { useQuery } from "@apollo/client";
import { GET_TENANTS } from "src/graphql/query/Tenant";
import { MenuComponent } from "./component/MenuComponent";
import { LoadingComponent } from "src/components/loading/loading-component";
import { ErrorComponent } from "src/components/error/error-component";
import { ButtonComponent } from "src/components/button/button-component";
import { ChooseTableNumberSection } from "./section/ChooseTableNumberSection";
import { MemberSection } from "./section/MemberSection";
import { rupiahFormat } from "src/helper/rupiahFormat";

export function CartPage() {
  const navigate = useNavigate();
  const { idTable } = useParams();

  const refMemberNumber = useRef(null);

  const { cart, setCart } = useContext(CartContext);

  const [idMember, setIdMember] = useState(null);

  const {
    loading: loadingTenants,
    error: errorTenants,
    data: dataTenants,
    refetch: refetchTenants,
  } = useQuery(GET_TENANTS);


  const subTotal = cart.reduce(
    (temp, x) => (x.buy == true ? temp + x.originalPrice * x.amount : temp),
    0,
    0
  );

  const tax = subTotal * 0.1;

  if (loadingTenants) return <LoadingComponent />;
  if (errorTenants) return <ErrorComponent />;

  return (
    <div>
      <MemberSection
        myRef={refMemberNumber}
        callback={(value) => {
          setIdMember(value);
          refMemberNumber.current?.close();
          navigate(`/${idTable}/payment/${value}`);
          // refTableNumber.current?.showModal();
        }}
        handleCancel={() => {
          refMemberNumber.current?.close();
        }}
      />

      {/* <ChooseTableNumberSection
        myRef={refTableNumber}
        callback={(value) => {
          refTableNumber.current?.close();
          navigate(`/${idTable}payment/${idMember}/${value}`);
        }}
        handleCancel={() => {
          refTableNumber.current?.close();
        }}
      /> */}

      <NavbarComponent
        title="Pesanan"
        type="dark"
        leftIcon={FiArrowLeft}
        handleLeft={() => {
          navigate(-1);
        }}
        rightIcon={FiHome}
        handleRight={() => {
          navigate(`/`);
        }}
      />

      <div className="w-full bg-white grow flex flex-col gap-2 overflow-scroll">
        {cart.length == 0 ? <EmptyComponent /> : null}
        {dataTenants.tenants.map((tenant, index) => {
          return tenant.menus.map((menu, i) => {
            if (cart.filter((x) => x.menuId == menu.id).length > 0) {
              return <MenuComponent menu={menu} key={`menu_${menu.id}`} />;
            }
          });
        })}
        <div className="bg-slate-200 h-0.5 w-full"></div>

        <div className="px-4">
          <div className="mt-2">
            <div className="flex justify-between items-center">
              <p className="f-p2-r">Sub Total</p>
              <p className="f-p2-sb">{rupiahFormat(subTotal)}</p>
            </div>
            <div className="flex justify-between items-center">
              <p className="f-p2-r">Discount</p>
              <p className="f-p2-sb">{rupiahFormat(0)}</p>
            </div>
            <div className="flex justify-between items-center">
              <p className="f-p2-r">Tax</p>
              <p className="f-p2-sb">{rupiahFormat(tax)}</p>
            </div>
          </div>

          <div className="mt-2 flex items-center justify-between">
            <h3 className="f-h4">Total</h3>
            <h3 className="f-h4 text-secondary-border">{rupiahFormat(subTotal + tax)}</h3>
          </div>

          <div className="mt-4">
            <ButtonComponent
              title="Payment"
              handle={() => {
                if (
                  cart.reduce(
                    (temp, x) => (x.buy == true ? temp + 1 : temp),
                    0,
                    0
                  ) > 0
                )
                  refMemberNumber.current?.showModal();
              }}
            />
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}
