import "./App.css";
import { useEffect, useState } from "react";
import { AppContextProvider } from "./context/AppContextProvider";
import { SplashPage } from "./pages/splash";
import GeneralRouter from "./router";

function App() {
  return (
    <AppContextProvider>
      <UserManager />
    </AppContextProvider>
  );
}

export default App;

function UserManager() {
  const [load, setLoad] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 1500);
  }, []);

  if (load) {
    return <SplashPage />;
  } else {
    return <GeneralRouter />;
  }
}
