import React from "react";
import logoWhite from "src/assets/images/logo-white.svg";
import bubbleRandom from "src/assets/images/bubble-random.svg";
import { FiChevronUp } from "react-icons/fi";

export function SplashPage() {
  return (
    <div className="bg-primary-border relative flex flex-col justify-center items-center h-screen overflow-hidden">
      <img src={bubbleRandom} className="w-full h-full absolute" />
      <div className="absolute w-[200px] h-[500px] flex flex-col items-center justify-between">
        <div className="flex flex-col">
          <img src={logoWhite} className="" />
          <h6 className="f-p2-r text-white text-center mt-2">
            Your Partner to Grow
          </h6>
        </div>
        <button className="bg-secondary-main rounded-full p-3">
          <FiChevronUp size={24} color="#fff" />
        </button>
      </div>
    </div>
  );
}
