import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { BillPage } from "src/pages/bill";
import { CartPage } from "src/pages/cart";
import { HomePage } from "src/pages/home";
import { MenuPage } from "src/pages/menu";
import { MenuDetailPage } from "src/pages/menu-detail";
import { PaymentPage } from "src/pages/payment";

export default function GeneralRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:idTable" element={<HomePage />} />
        <Route path="/:idTable/menu" element={<MenuPage />} />
        <Route path="/:idTable/menu/:id" element={<MenuDetailPage />} />
        <Route path="/:idTable/cart" element={<CartPage />} />
        <Route path="/:idTable/payment/:idMember/" element={<PaymentPage />} />
        <Route path="/:idTable/bill" element={<BillPage />} />
        <Route path="*" element={<Navigate to="/1" />} />
      </Routes>
    </BrowserRouter>
  );
}
