import image404 from "src/assets/images/404.png";

export function ErrorComponent() {
  return (
    <div className="w-full min-h-screen flex flex-col justify-center items-center">
      <img src={image404} />
      <h1 className="text-center font-bold f-h4 text-gray-900">
        No Internet Connection!
      </h1>
    </div>
  );
}
